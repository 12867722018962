import request from '@/utils/request'
import settings from '@/settings'

export function getTokenInfo(token) {
    return request({
        baseURL: settings.token_url,
        url: '/admintoken/' + token,
        method: 'get',
    })
}
