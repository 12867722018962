import defaultSettings from '@/settings'
import { getOSToken } from "@/utils/auth"

const title = defaultSettings.title || 'MadOps'

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${getOSToken()} - ${pageTitle} - ${title}`
  }
  return `${title}`
}
