import { constantRoutes } from '@/router'
import { getTokenInfo } from "@/api/auth";
import { MessageBox, Message } from "element-ui";

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

const state = {
  routes: [],
  addRoutes: [],
  init_route: false
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
    state.init_route = true;
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes = []
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    });
  },

  verifyToken({ commit, state }, token) {
    return new Promise((resolve, reject) => {
      getTokenInfo(token).then(response => {
        resolve(response);
      }).catch(err => {
        Message({
          message: err.message,
          type: "error",
          duration: 5 * 1000
        });
        reject(err);
      });
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
