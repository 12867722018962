import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css/normalize.css'

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import JsonViewer from 'vue-json-viewer'

import './styles/element-variables.scss'
import './styles/index.scss'
import './icons'

import './permission'

Vue.config.productionTip = false

Vue.use(Element, { locale })
Vue.use(JsonViewer)

Vue.config.errorHandler = err => {
  console.log('Exception: ', err);
}

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})