import router, { resetRouter } from '@/router'
import { removeToken } from "@/utils/auth"

const state = {
  token: '',
  name: '',
  avatar: '',
  email: '',
  username: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_INIT_ROUTE: (state, initRoute) => {
    state.init_route = initRoute
  }
}

const actions = {
  setInfo({ commit }, data) {
    commit('SET_ROLES', data.roles);
    commit('SET_NAME', data.name);
    commit('SET_EMAIL', data.email);
    commit('SET_AVATAR', data.avatar);

    var splitEmail = data.email.split("@");
    commit('SET_USERNAME', splitEmail[0]);
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      removeToken();
      resolve(true);
    });
  },

  // remove token
  resetToken({ commit }) {

  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
