import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import setting from '@/settings.js'
import { getToken, getOSToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: setting.firebase_url, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['admin-token'] = getToken();
    }

    // set request header OS information (example: Android, iOS,...)
    config.headers['x-game-os'] = getOSToken();

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    //console.log('res:' + JSON.stringify(res)) // for debug
    return res;
  },
  error => {
    console.log('err:' + error) // for debug
    if (error.response)
    {
      Message({
        message: error.message + ": " + JSON.stringify(error.response.data),
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
