import Cookies from 'js-cookie'

const TokenKey = 'admin-token'
const OSKey = 'admin-os_key'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function setOSToken(os) {
    return Cookies.set(OSKey, os)
}

export function getOSToken() {
    return Cookies.get(OSKey)
}


export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getAvatarURL(name) {
    let str = name.replace(".", "");
    let nameSplit = str.split(" ");

    let baseName = nameSplit[0];
    if (nameSplit.length >= 2)
        baseName = nameSplit[0] + "+" + nameSplit[1];

    let avatar = `https://ui-avatars.com/api/?name=${baseName}&background=1890FF&color=fff&size=80`;
    return avatar;
}